import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "./App.css";

function Skills({ skills }) {
  return (
    <Box
      sx={{
        // m: { xs: 1, sm: 4 },
        alignItems: { xs: "center", sm: "flex-start" },
        justifyContent: { xs: "center", sm: "center" },
      }}
    >
      <Typography variant="h4" gutterBottom component="div">
        SKILLS
      </Typography>
      <Grid container>
        <Grid item xs={0.2} sm={1.5}></Grid>
        <Grid item xs={11} md={9}>
          <Grid
            container
            sx={{ mt: 4 }}
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            {skills &&
              skills.map((skill, index) => (
                <Grid
                  item
                  xs={10}
                  sm={8}
                  md={5}
                  lg={4}
                  key={`certificate-${skill.category}`}
                >
                  <Skill skill={skill} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function Skill({ skill }) {
  return (
    <Card
      sx={{
        display: "flex",
        p: 1,
        flexWrap: "wrap",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {skill.category}
          </Typography>
          <Divider />
          {skill.skillSet &&
            skill.skillSet.map((ele, index) => (
              <SkillSet key={`skill-${index}`} ele={ele} />
            ))}
        </CardContent>
      </Box>
    </Card>
  );
}

function SkillSet({ ele }) {
  return (
    <>
      <Stack
        spacing={2}
        sx={{ mt: 1, p: 1 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            image={`/static/images/langs/${ele.name.toLowerCase()}.svg`}
          />
          <Typography component="div" variant="body1">
            {ele.name}
          </Typography>
        </Stack>

        <Rating
          readOnly
          precision={0.5}
          name="size-medium"
          defaultValue={ele.rating}
        />
      </Stack>
    </>
  );
}

export default Skills;
