import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Phone from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Avatar, Button, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { amber } from "@mui/material/colors";
import "./App.css";
import Certificates from "./Certificates";
import configData from "./info.json";
import Projects from "./Projects";
import Skills from "./Skills";
import React from "react";

function App() {
  let subDomainName = window.location.hostname.split(".")[0];

  if (subDomainName !== "cv" && subDomainName !== "jd") {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get("type");
    if (type !== undefined) {
      subDomainName = type;
    }
  }
  const cv = subDomainName === "cv"; // Show Freelance and OC
  const jd = subDomainName === "jd"; // Show OC

  return (
    <div className="App">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          bgcolor: "#e4e4e4",
          alignItems: "center",
          overflow: "hidden",
          fontWeight: "bold",
        }}
      >
        {getIntroduction(cv, jd)}
        <Certificates certificates={configData.certificates} />
        <Skills skills={configData.skills} />
        <Projects projects={configData.projects} label={"PROJECTS"} />
        {cv && (
          <Projects
            projects={configData.freelanceProjects}
            label={"FREELANCE PROJECTS"}
          />
        )}
        {(jd || cv) && (
          <Projects
            projects={configData.ownProjects}
            label={"STARTUP PROJECTS"}
          />
        )}
        {getContactInfo()}
        {footer()}
      </Box>
    </div>
  );
}

function getIntroduction(cv, jd) {
  let resume = cv || jd ? "Trinath_cv.pdf" : "Trinath.pdf";

  return (
    <>
      <Avatar
        alt="Remy Sharp"
        src="/static/images/avatar/1.jpeg"
        sx={{
          width: 200,
          height: 200,
          bottom: -40,
          border: "5px solid #fff",
          borderRadius: "50%",
          boxShadow: 1,
        }}
      />
      <Box
        sx={{
          width: "70%",
          p: 5,
          bgcolor: "background.paper",
          border: "10px solid #fff",
          boxShadow: 1,
          flexGrow: 1,
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Typography variant="h4" gutterBottom component="div">
          {configData.name}
        </Typography>
        <Typography variant="h5" gutterBottom component="div">
          {configData.designation}
        </Typography>
        {configData.currentDesignation &&
          configData.currentDesignation
            .filter((e, index) => {
              if (index === 1 && (jd || cv)) {
                return true;
              } else if (index === 2 && cv) {
                return true;
              } else if (index !== 1 && index !== 2) {
                return true;
              } else {
                return false;
              }
            })
            .map((e, index) => (
              <Typography
                key={`currentDesignation-${index}`}
                variant="body2"
                gutterBottom
                component="div"
              >
                {e}
              </Typography>
            ))}
        <Stack
          spacing={2}
          sx={{ p: 2 }}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <GitHubIcon
            fontSize="large"
            onClick={() => openLink(configData.githubUrl, true)}
          />
          <LinkedInIcon
            fontSize="large"
            onClick={() => openLink(configData.linkedinUrl, true)}
          />
          <YouTubeIcon
            fontSize="large"
            onClick={() => openLink(configData.youtubeUrl, true)}
          />
        </Stack>
        <Typography variant="body1" gutterBottom component="div" paragraph>
          {configData.aboutMe}
        </Typography>
        <Divider />
        <Stack
          spacing={2}
          sx={{ mt: 2, p: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={() =>
              openLink(process.env.PUBLIC_URL + `/${resume}`, true)
            }
          >
            Download Resume
          </Button>
        </Stack>
      </Box>
    </>
  );
}

function openLink(url, newTab) {
  newTab
    ? window.open(url, "_blank", "noopener noreferrer")
    : (window.location.href = url);
}

function getContactInfo() {
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          flexGrow: 1,
          p: 3,
          m: 4,
          display: "flex",
          flexWrap: "wrap",
          alignItems: { xs: "center", sm: "flex-start" },
          justifyContent: { xs: "flex-start", sm: "center" },
          width: { xs: 300, sm: 1000 },
        }}
      >
        <Stack
          spacing={2}
          sx={{ mt: 1, p: 1 }}
          direction="column"
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="flex-start"
        >
          <Stack spacing={2} direction="row">
            <MailOutlineIcon fontSize="large" sx={{ color: amber[500] }} />
            <Typography variant="body1" gutterBottom component="div" paragraph>
              {configData.email}
            </Typography>
          </Stack>
          <Stack spacing={2} direction="row">
            <Phone fontSize="large" sx={{ color: amber[500] }} />
            <Typography variant="body1" gutterBottom component="div">
              {configData.phone}
            </Typography>
          </Stack>

          <Stack spacing={2} direction="row">
            <PublicIcon fontSize="large" sx={{ color: amber[500] }} />
            <Typography variant="body1" gutterBottom component="div">
              {configData.url}
            </Typography>
          </Stack>

          <Stack spacing={2} direction="row">
            <LocationOnIcon fontSize="large" sx={{ color: amber[500] }} />
            <Typography variant="body1" gutterBottom component="div">
              {configData.address}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

function footer() {
  return (
    <>
      <Box
        sx={{
          p: 2,
          width: "100%",
          bgcolor: "#1976d2",
        }}
      >
        <Typography variant="body1" gutterBottom component="div" color="#ffff">
          {configData.license} {configData.name} - {new Date().getFullYear()}
        </Typography>
      </Box>
    </>
  );
}

export default App;
