import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./App.css";
import ProjectInfo from "./ProjectInfo";

function Projects({ label, projects }) {
  return (
    <>
      <Box
        sx={{
          p: 3,
          mt: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom component="div">
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 5,
          // width: "70%",
        }}
      >
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {projects.map((project, index) => (
                <Grid item xs={10} md={5} key={`project-${index}`}>
                  <ProjectInfo project={project} index={project} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={1}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Projects;
