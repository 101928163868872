import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import "./App.css";

function Certificates(data) {
  let certificates = data.certificates;
  return (
    <Box
      sx={{
        m: 1,
        mt: 4,
        p: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom component="div">
        CERTIFICATES
      </Typography>

      <Box
        sx={{
          m: 1,
          p: 1,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: { xs: "center", md: "center" },
          alignItems: "flex-start",
          overflow: "auto",
          width: "70%",
        }}
      >
        {certificates &&
          certificates.map((certificate, index) => (
            <Certificate
              key={`certificate-${certificate.certificateName}`}
              certificate={certificate}
            />
          ))}
      </Box>
    </Box>
  );
}

function Certificate(data) {
  let certificate = data.certificate;
  return (
    <Card
      sx={{
        display: "flex",
        p: 2,
        m: 1,
        flexWrap: "wrap",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "center", sm: "flex-start" },
        width: 350,
      }}
      onClick={() =>
        certificate.certificateLink != null
          ? openLink(certificate.certificateLink, true)
          : null
      }
    >
      <CardMedia
        component="img"
        sx={{ width: 100 }}
        image={certificate.certificateLogo}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {certificate.certificateName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {certificate.certificateNo}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

function openLink(url, newTab) {
  newTab
    ? window.open(url, "_blank", "noopener noreferrer")
    : (window.location.href = url);
}

export default Certificates;
