import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  Button,
  Card,
  CardMedia,
  Chip,
  Dialog,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import "./App.css";

function ProjectInfo({ project, index }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <Card
        sx={{
          p: 2,
          bgcolor: "background.paper",
          border: "10px solid #fff",
          boxShadow: 1,
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          height: { xs: 250 },
          overflow: "auto",
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CardMedia
            component="img"
            sx={{ width: 50 }}
            image={project.companyLogo}
          />

          <Typography variant="h5" gutterBottom component="div">
            {project.projectName}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          sx={{ mt: 1, p: 1 }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            spacing={2}
            sx={{ mt: 1, p: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <DateRangeIcon fontSize="small" />
            <Typography variant="body2" gutterBottom component="div">
              {project.date}
            </Typography>
          </Stack>
          <Typography variant="body1" gutterBottom component="div">
            {project.info}
          </Typography>
          <Button variant="contained" onClick={handleClickOpen}>
            Read More
          </Button>
        </Stack>
      </Card>
      <SimpleDialog selectedValue={project} open={open} onClose={handleClose} />
    </div>
  );
}

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog fullWidth maxWidth={"lg"} onClose={handleClose} open={open}>
      <Box
        sx={{
          display: "flex",
          p: 1,
          flexDirection: { xs: "column" },
          bgcolor: "#e4e4e4",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CardMedia
            component="img"
            sx={{ width: 50 }}
            image={selectedValue.companyLogo}
          />
          <Typography sx={{ mt: 2 }} variant="h4" gutterBottom component="div">
            {selectedValue.projectName}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          p: 3,
          flexDirection: { xs: "column" },
          fontWeight: "bold",
        }}
      >
        <Stack
          spacing={2}
          sx={{ pl: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="body1">Company Name :</Typography>
          <Typography variant="body2">{selectedValue.companyName}</Typography>
        </Stack>
        <Stack
          spacing={2}
          sx={{ pl: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="body1">Designation :</Typography>
          <Typography variant="body2">{selectedValue.designation}</Typography>
        </Stack>
        {selectedValue.teamSize && (
          <Stack
            spacing={2}
            sx={{ pl: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography variant="body1">Team Size :</Typography>
            <Typography variant="body2">{selectedValue.teamSize}</Typography>
          </Stack>
        )}
      </Box>
      <Stack
        spacing={2}
        sx={{ p: 2 }}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">About Product</Typography>
        <Typography variant="body2" paragraph component="div">
          {selectedValue.details}
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={2}>
        <Typography variant="h6" gutterBottom component="div">
          Stack
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box
          sx={{
            p: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedValue.stack.map((stack) => (
            <Chip sx={{ m: 1 }} key={`stack-value-${stack}`} label={stack} />
          ))}
        </Box>
      </Stack>

      {selectedValue.achievements && (
        <Stack alignItems="center" sx={{ mt: 1 }} spacing={2}>
          <Typography variant="h6" gutterBottom component="div">
            Roles and Responsibilities
          </Typography>
        </Stack>
      )}

      <Stack spacing={2} sx={{ mb: 5, ml: 5 }} direction="column">
        {selectedValue.achievements &&
          selectedValue.achievements.map((achievement) => (
            <Stack
              key={`stack-value-${achievement}`}
              direction="row"
              spacing={2}
            >
              <ArrowRightIcon />
              <Typography variant="body2" gutterBottom component="div">
                {achievement}
              </Typography>
            </Stack>
          ))}
      </Stack>
    </Dialog>
  );
}

export default ProjectInfo;
